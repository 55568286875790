<template>
    <vx-card :title="'Detail Handover From Sales'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Canvas Item Request Document</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="code" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Canvas Handover Document</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="handoverCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Territory</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedTerritory ? selectedTerritory.label : ''" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedWarehouse ? selectedWarehouse.label : ''" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Sales</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedSales ? selectedSales.label : ''" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Loading Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="formatDate(loadingDate)" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>

        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <vs-table stripe border style="width:100%" :data="dataLines">
                    <template slot="thead">
                        <vs-th>Warehouse Handedover</vs-th>
                        <vs-th>SKU Code</vs-th>
                        <vs-th>Qty</vs-th>
                        <vs-th>HU</vs-th>
                        <vs-th>Qty UOM</vs-th>
                        <vs-th>Batch</vs-th>
                        <vs-th>Exp_Date</vs-th>
                        <vs-th>Sales Handedover</vs-th>
                    </template>

                    <template slot-scope="">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in dataLines">
                            <vs-td>
                                <vs-checkbox
                                    :disabled="true"
                                    v-model="tr.is_handedover"
                                ></vs-checkbox>
                            </vs-td>
                            <vs-td>{{ tr.selected_sku ? tr.selected_sku.label : '' }}</vs-td>
                            <vs-td>{{ tr.qty }}</vs-td>
                            <vs-td>{{ tr.selected_hu }}</vs-td>
                            <vs-td>{{ tr.qty_uom }}</vs-td>
                            <vs-td>{{ tr.batch }}</vs-td>
                            <vs-td>{{ formatDate(tr.expired_date) }}</vs-td>
                            <vs-td>
                                <vs-checkbox
                                    :disabled="true"
                                    v-model="tr.is_sales_handover"
                                ></vs-checkbox>
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";

export default {
    components: {},
    data() {
        return {
            id: null,
            readonly: true,
            status: null,
            code: null,
            handoverCode: null,
            selectedWarehouse: null,
            selectedTerritory: null,
            selectedSales: null,
            loadingDate: null,
            checked: [],
            dataLines: [
                {
                    line_id: 0,
                    selected_sku: null,
                    selected_hu: null,
                    qty: 0,
                    qty_uom: 0,
                    batch: '',
                    expired_date: null,
                    is_handedover: false,
                    is_sales_handover: false,
                }
            ],
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http.get("/api/wms/v1/canvas/loading/handover-detail/" + this.id)
            .then((resp) => {
                if (resp.code == 200) {
                    this.status = resp.data.status
                    this.code = resp.data.code
                    this.handoverCode = resp.data.handover_code
                    this.selectedWarehouse = resp.data.selected_warehouse
                    this.selectedTerritory = resp.data.selected_territory
                    this.selectedSales = resp.data.selected_sales
                    this.loadingDate = moment(resp.data.loading_date).toDate()
                    this.dataLines = resp.data.data_lines
                    this.dataLines.forEach((val) => {
                        if (val.is_handedover) {
                            this.checked.push(val.line_id)
                        }
                    })
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            })
            .catch((error) => {
                this.$vs.loading.close();
                console.log(error)
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: error,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
            });
        },
        handleBack() {
            this.$router.push({
                name: "canvas.handover",
                params: {status: 1}
            });
        },
    },
    watch: {},
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
}
</script>